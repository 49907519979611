<template>
  <div class="p-wrap">
    <!-- 功能区 -->
    <div class="p-header">
      <div class="header-title">
        <div class="title-left">购方信息维护</div>
        <div class="title-right">
          <span @click="handleShowDialog('insert')"> <img src="@/assets/icon/goodsInfo/insert.png" />新增客户 </span>
          <span @click="importVisible = true"> <img src="@/assets/icon/goodsInfo/income.png" />批量导入 </span>
        </div>
      </div>
      <div class="header-select">
        <el-form class="form" ref="customerFormList" :model="filter" label-width="100px"   :inline="true">
          <!-- <el-row> -->
            <!-- <el-col :span="6"> -->
              <el-form-item label="所属组织">
                <organization-select :model="filter"></organization-select>
              </el-form-item>
            <!-- </el-col>
            <el-col :span="6"> -->
              <el-form-item label="名称">
                <el-input v-model="filter.name" placeholder="请输入客户名称" @keyup.enter.native="handleQuery()" />
              </el-form-item>
            <!-- </el-col>
            <el-col :span="6"> -->
              <el-form-item label="编码">
                <el-input v-model="filter.code" placeholder="请输入客户编码" @keyup.enter.native="handleQuery()"></el-input>
              </el-form-item>
            <!-- </el-col>
            <el-col :span="5"> -->
              <el-form-item>
                <span v-if="!selectExpended" @click="selectExpended = !selectExpended" style="color: #1890ff">展开<i class="el-icon-arrow-down"></i></span>
                <span v-else @click="selectExpended = !selectExpended" style="color: #1890ff">收起<i class="el-icon-arrow-up"></i></span>
                <el-button type="default" @click="handleReset">重置</el-button>
                <el-button type="primary" @click="handleQuery()">查询</el-button>
              </el-form-item>
             
            <!-- </el-col> -->
          <!-- </el-row> -->
          <el-row style="margin-top: 10px" v-if="selectExpended">
            <!-- <el-col :span="6">
              <el-form-item label="往来类别">
                <el-select v-model="filter.contactType" placeholder="往来类别" clearable style="width: 220px">
                  <el-option v-for="type in contactType" :key="type.value" :label="type.label" :value="type.value"/>
                </el-select>
              </el-form-item>
            </el-col> -->
            <!-- <el-col :span="6"> -->
              <el-form-item label="税号">
                <el-input v-model="filter.taxNo" placeholder="请输入客户税号" @keyup.enter.native="handleQuery()"/>
              </el-form-item>
            <!-- </el-col> -->
            <!-- <el-col :span="6">
              <el-form-item label="客户标签">
                <el-select v-model="filter.labelId" clearable placeholder="客户标签" style="width: 220px">
                  <el-option v-for="type in labelData" :key="type.value" :label="type.label" :value="type.value"/>
                </el-select>
              </el-form-item>
            </el-col> -->
          </el-row>
        </el-form>
      </div>
    </div>
    <!-- 内容区 -->
    <div class="p-content">
      <div class="main-top">
        <div class="top-left">
          <span>客户列表</span>
          <el-popover placement="right" width="80" trigger="click">
            <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange"> 全选 </el-checkbox>
            <div class="part"></div>
            <el-checkbox-group v-model="colOptions" @change="handleCheckedCitiesChange">
              <el-checkbox v-for="item in colSelect" :label="item" :key="item" style="display: block; margin-top: 6px"></el-checkbox>
            </el-checkbox-group>
            <div slot="reference" class="rowSet">
              <img class="imgSet" src="@/assets/icon/set.png" />
              <span>列设置</span>
            </div>
          </el-popover>
        </div>
        <div>
          <!-- <el-button type="primary" @click="handlePushCustom">推送</el-button>
          <el-button type="primary" @click="handleSyncCustom">同步往来单位</el-button> -->
          <el-button type="primary" @click="handleBatchExport">批量导出</el-button>
          <el-button type="danger" @click="handleBatchDelete">批量删除</el-button>
        </div>
      </div>
      <div class="p-table">
        <el-table ref="customerInfoTable" v-loading="tableLoading" :data="records" :header-cell-style="handleHeaderCellStyle" border
                  stripe @selection-change="handleSelection">
          <el-table-column fixed="left" type="selection" width="50"/>
          <el-table-column type="index" label="序号" width="60"></el-table-column>
          <!-- <el-table-column v-if="colData[0] && colData[0].isTrue" prop="code" label="编码"></el-table-column> -->
          <el-table-column v-if="colData[0] && colData[0].isTrue" label="名称" min-width="140" prop="name"
                           show-overflow-tooltip></el-table-column>
          <!-- <el-table-column v-if="colData[2] && colData[2].isTrue" label="往来类别" min-width="70"
                           prop="contactType" show-overflow-tooltip></el-table-column>
          <el-table-column v-if="colData[3] && colData[3].isTrue"  :formatter="customerFormat" label="客户类型"
                           min-width="100"
                           prop="type" show-overflow-tooltip></el-table-column>
          <el-table-column v-if="colData[14] && colData[14].isTrue" label="客户标签" min-width="100"
                           prop="labelName" show-overflow-tooltip></el-table-column> -->
          <el-table-column v-if="colData[1] && colData[1].isTrue" label="税号" min-width="160" prop="taxNo"
                           show-overflow-tooltip></el-table-column>
          <el-table-column v-if="colData[2] && colData[2].isTrue" label="地址电话" min-width="140"
                           prop="addressTel" show-overflow-tooltip></el-table-column>
          <el-table-column v-if="colData[3] && colData[3].isTrue" label="开户行及账号" min-width="140"
                           prop="bankAccount" show-overflow-tooltip></el-table-column>
          <el-table-column v-if="colData[4] && colData[4].isTrue" label="邮箱地址" min-width="140" prop="email"
                           show-overflow-tooltip></el-table-column>
          <el-table-column v-if="colData[5] && colData[5].isTrue" label="联系人" min-width="80" prop="contact"
                           show-overflow-tooltip></el-table-column>
          <el-table-column v-if="colData[6] && colData[6].isTrue" label="联系人电话" prop="contactTel" show-overflow-tooltip
                           width="120"></el-table-column>
          <el-table-column v-if="colData[7] && colData[7].isTrue" label="组织名称" prop="orgName" show-overflow-tooltip
                           width="160"></el-table-column>
          <!-- <el-table-column v-if="colData[9] && colData[9].isTrue" label="推送状态" prop="pushStatus"
                           show-overflow-tooltip>
            <template slot-scope="scope">
              {{
                scope.row.pushStatus == 0 ? '未推送' : scope.row.pushStatus == 1 ? '推送中' : scope.row.pushStatus == 2 ? '已推送' : '推送失败'
              }}
            </template>
          </el-table-column>
          <el-table-column v-if="colData[10] && colData[10].isTrue" label="失败原因" prop="operateMessage"
                           show-overflow-tooltip></el-table-column> -->
          <el-table-column v-if="colData[8] && colData[8].isTrue" fixed="right"  label="操作" prop="operate"
                           width="110">
            <template slot-scope="scope">
              <div class="operate-button">
                <el-button size="medium" style="padding: 0" type="text" @click="handleShowDialog('update', scope.row)">
                  编辑
                </el-button>
                <el-button size="medium" style="padding: 0" type="text" @click="handleDelete(scope.row)">删除
                </el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div v-if="records.length > 0" class="p-page">
        <el-pagination background @size-change="handleSizeChange" @current-change="handleQuery" :current-page.sync="filter.page" :page-sizes="[15, 30, 50, 100]" :page-size="filter.size" layout="total, sizes, prev, pager, next" :total="total"></el-pagination>
      </div>
    </div>
    <!-- 弹窗 -->
    <el-dialog append-to-body :title="editTitle" :visible.sync="editVisible" :show-close="false">
      <customer-edit v-if="editVisible" :customer="customer" ref="customerForm" @handleCancelEditDialog="handleCancelEditDialog"></customer-edit>
    </el-dialog>
    <el-dialog append-to-body title="批量导入" :visible.sync="importVisible" width="500px" :show-close="false">
      <customer-import @handReloadCustomerList="handReloadCustomerList" @handleCancelImportDialog="importVisible = false"></customer-import>
    </el-dialog>
    <!-- 批量删除 -->
    <el-dialog append-to-body width="20%" :visible.sync="invoiceBatchDeleteVisible">
      <div class="dialog-delete">
        <span>是否删除选中客户信息?</span>
      </div>
      <div class="dialog-footer" slot="footer">
        <el-button type="default" @click="cancelDelete">取消</el-button>
        <el-button type="primary" @click="confirmDelete">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {tableStyle} from '@/util/mixins';
import {deleteCustomerByIds, handleExportExcel, list, pushCustomer, syncCustom} from '@/service/customer.js';
import OrganizationSelect from '@/components/widgets/OrganizationSelect';
import CustomerTemplateDownload from './CustomerTemplateDownload';
import CustomerImport from './CustomerImport';
import CustomerEdit from './CustomerEdit';
import {CUSTOMER_INFO_LIST} from '@/content/customerInfo';
import ProjectImport from '@/views/voucher-manage/items/ProjectImport.vue';
import {getAllLabels} from "@/service/system/label";

export default {
  name: 'customerList',
  components: { ProjectImport, OrganizationSelect, CustomerTemplateDownload, CustomerImport, CustomerEdit },
  mixins: [tableStyle],
  data() {
    return {
      labelData: [],
      colData: CUSTOMER_INFO_LIST,
      isIndeterminate: true,
      colOptions: [], //列选项
      checkAll: false, //全选
      selections: [], //选择行
      colSelect: CUSTOMER_INFO_LIST.map((i) => i.title),
      // contactType: [
      //   {label: '全部', value: ''},
      //   {label: '客户', value: '客户'},
      //   {label: '两者皆是', value: '两者皆是'},
      //   { label: '供应商', value: '供应商' }
      // ],
      // 筛选条件
      filter: {
        orgId: 0,
        code: '',
        name: '',
        taxNo: '',
        // contactType: '',
        labelId: '',
        page: 1,
        size: 15
      },
      customer: {
        orgId: Number(localStorage.getItem('orgId')),
        code: undefined,
        name: ''
      },
      // 版本号信息
      versionInfo: {
        orgId: '',
        taxVersionNo: ''
      },
      tableLoading: false, // 表格加载loading
      versionVisible: false, // 版本号弹窗
      importVisible: false, // 导入弹窗
      editVisible: false, // 编辑弹窗
      editTitle: '', // 编辑弹窗标题
      records: [],
      total: 0, // 数据总数count
      serialNo: '', //商品编码
      invoiceBatchDeleteVisible: false,
      singleCustomerSign: false,
      singleCustomerId: '',
      selectExpended: false,
      params: {
        ids: ''
      }
    };
  },
  created() {
    this.handleQuery();
    this.settingOptions();
    this.handleGetlineSet();
    this.initLabels()
  },
  watch: {
    colOptions(valArr) {
      const arr = this.colSelect.filter((i) => valArr.indexOf(i) < 0); // 未选中
      this.colData.filter((i) => {
        if (arr.indexOf(i.title) != -1) {
          i.isTrue = false;
          this.$nextTick(() => {
            this.$refs.customerInfoTable.doLayout();
          });
        } else {
          i.isTrue = true;
          this.$nextTick(() => {
            this.$refs.customerInfoTable.doLayout();
          });
        }
      });
      localStorage.setItem('customerInfo', JSON.stringify(this.colData));
    }
  },
  methods: {
    async initLabels() {
      const {success, data} = await getAllLabels();
      if (success && data) {
        let arr = []
        arr.push({label: '全部', value: ''},)
        for (let d of data) {
          arr.push({
            label: d.name,
            value: d.id
          })
          this.labelData = arr
        }
      }
    },
    handReloadCustomerList() {
      this.handleQuery();
    },
    // 客户格式化
    customerFormat(row, column) {
      let value = row[column.property];
      switch (column.label) {
        case '客户类型':
          if (value === '1') {
            return '一般纳税人';
          } else if (value === '2') {
            return '小规模纳税人';
          } else {
            return '其他';
          }
      }
    },
    // 弹窗控制
    handleShowDialog(type, val) {
      this.editTitle = type == 'insert' ? '添加客户信息' : '编辑客户信息';
      this.editVisible = true;
      if (val) {
        this.customer = JSON.parse(JSON.stringify(val));
      } else {
        this.customer = {
          orgId: Number(localStorage.getItem('orgId')),
          code: undefined,
          name: undefined
        };
      }
    },
    // 重置表单
    handleReset() {
      this.filter.orgId = 0;
      this.filter.code = '';
      this.filter.name = '';
      this.filter.taxNo = '';
      // this.filter.contactType = '';
      this.filter.labelId = '';
    },
    // 查询
    async handleQuery(val) {
      this.filter.page = 1;
      if (val) {
        this.filter.page = val;
      }

      this.tableLoading = true;
      const { success, data } = await list(this.filter);
      this.tableLoading = false;
      if (success) {
        this.records = data.records;
        this.total = data.total;
      }
    },
    // 切换分页条数
    handleSizeChange(val) {
      this.filter.size = val;
      this.handleQuery();
    },
    // 删除商品
    async handleDelete(row) {
      this.invoiceBatchDeleteVisible = true;
      this.singleCustomerSign = true;
      this.singleCustomerId = row.id;
    },
    // 关闭商品dialog
    handleCancelEditDialog(status) {
      this.editVisible = false;
      if (status == 'success') {
        this.handleQuery();
      }
      this.$refs.customerForm.resetForm();
    },
    /* 表格数据选中 */
    handleSelection(value) {
      this.selections = value;
      this.params.ids = value.map((item) => item.id);
    },
    //获取列设置
    handleGetlineSet() {
      if (JSON.parse(localStorage.getItem('customerInfo')) != null) {
        this.colData = JSON.parse(localStorage.getItem('customerInfo'));
        if (this.colData.filter((i) => i.isTrue == true).length === this.colSelect.length) {
          this.checkAll = true;
          this.isIndeterminate = false;
        }
        this.colOptions = JSON.parse(localStorage.getItem('customerInfo'))
          .filter((i) => i.isTrue === true)
          .map((item) => item.title); //选中
      } else {
        this.colOptions = this.colData.map((item) => item.title); //默认所有
        this.checkAll = true;
        this.isIndeterminate = false;
      }
    },
    //重置列设置
    handleCheckReset() {
      this.colOptions = this.colSelect;
      this.checkAll = true;
      this.isIndeterminate = false;
    },
    //列设置全选
    handleCheckAllChange(val) {
      this.colOptions = val ? this.colSelect : [];
      this.isIndeterminate = false;
    },
    //列设置改变
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.colSelect.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.colSelect.length;
    },
    //导出excel
    async handleBatchExport() {
      if (this.selections.length == 0) {
        this.toast('请勾选需要导出的客户信息', 'warning');
        return;
      }
      let items = this.selections;
      this.exportLoading = true;
      let ids = items.map((item) => item.id);
      const rsp = await handleExportExcel(ids);
      this.exportLoading = false;
      this.downloadFile(rsp);
    },
    //同步往来单位
    async handleSyncCustom() {
      const { success } = await syncCustom('');
      if (success) {
        this.toast('同步数据成功', 'success', () => this.handleQuery());
      } else {
        this.toast('同步数据失败！', 'error');
      }
    },
    //推送往来单位
    async handlePushCustom() {
      // 判断this.params.ids是否为空
      if (this.params.ids.length == 0) {
        this.toast('请勾选需要推送的客户信息', 'warning');
        return;
      }
      const { success } = await pushCustomer(this.params);
      if (success) {
        this.toast('推送客户信息成功', 'success', () => this.handleQuery());
      }
    },
    /* 发票删除弹窗 */
    handleBatchDelete() {
      if (this.selections.length == 0) {
        this.toast('请勾选客户信息！', 'warning');
        return;
      }
      this.invoiceBatchDeleteVisible = true;
    },
    /* 批量删除选中票据 */
    async confirmDelete() {
      if (this.singleCustomerSign) {
        // 单条删除
        let ids = this.singleCustomerId;
        const { success, message } = await deleteCustomerByIds(ids);
        if (success) {
          this.toast('删除成功', 'success', () => this.handleQuery());
          this.invoiceBatchDeleteVisible = false;
          this.cancelDelete();
        } else {
          this.toast(message, 'error');
          this.cancelDelete();
        }
      } else {
        // 批量删除
        let ids = this.selections.map((i) => i.id);
        const { success, message } = await deleteCustomerByIds(ids);
        if (success) {
          this.toast('删除成功！', 'success', () => {
            this.invoiceBatchDeleteVisible = false;
            this.handleQuery();
            this.cancelDelete();
          });
        } else {
          this.toast(message, 'error');
          this.cancelDelete();
        }
      }
      // 重置单条删除标记
      this.singleCustomerSign = false;
    },
    /* 取消选中 */
    cancelDelete() {
      this.selections = [];
      this.invoiceBatchDeleteVisible = false;
      this.$refs.customerInfoTable.clearSelection();
    },
    settingOptions() {}
  }
};
</script>

<style scoped lang="scss">
.p-header {
  .header-title {
    display: flex;
    padding: 16px 24px;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid #e9e9e9;

    .title-right {
      display: inline-flex;

      span {
        color: #3d94ff;
        margin-right: 20px;
        font-weight: 500;
        font-size: 16px;

        img {
          width: 18px;
          height: 18px;
          vertical-align: sub;
          margin-right: 4px;
        }
      }

      span:hover {
        cursor: pointer;
      }
    }
  }
}

::v-deep .dialog-footer {
  padding-top: 25px;
  text-align: center;
}

::v-deep .p-content {
  .main-top {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;

    .top-left {
      display: flex;
      justify-content: flex-start;

      span {
        line-height: 32px;
        font-size: 16px;
        color: #666666;
      }

      .rowSet {
        display: flex;
        align-items: center;
        cursor: pointer;

        .imgSet {
          width: 20px;
          height: 20px;
          margin-right: 5px;
          margin-left: 5px;
        }

        span {
          color: #1890ff;
        }
      }
    }
  }
}

.part {
  width: 100%;
  height: 1px;
  background: #e5e5e5;
  margin: 15px 0;
}

.dialog-wrap {
  display: flex;
  height: 160px;
  flex-direction: column;

  .dialog-select {
    margin-bottom: 24px;

    label {
      width: 80px;
      text-align: left;
      display: inline-block;
    }

    label:before {
      content: '*';
      color: #f5222d;
      margin-right: 2px;
    }

    i {
      left: 140px;
      display: block;
      color: #f5222d;
      margin-top: 4px;
      position: absolute;
    }
  }

  .dialog-button {
    margin-top: 20px;
    margin-left: 110px;
  }

  .backImg {
    bottom: 0;
    width: 100%;
    opacity: 0.5;
    height: 44px;
    position: absolute;
  }
}
</style>
