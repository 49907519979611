/**
 * 客户信息
 *
 */

/**
 * CUSTOMER_INFO_LIST
 * 客户信息列表字段
 */
export const CUSTOMER_INFO_LIST = [

  {
    title: '名称',
    isTrue: true
  },
  // {
  //   title: '往来类别',
  //   isTrue: true
  // },
  // {
  //   title: '客户类型',
  //   isTrue: true
  // },

  {
    title: '税号',
    isTrue: true
  },
  {
    title: '地址电话',
    isTrue: true
  },
  {
    title: '开户行及账号',
    isTrue: true
  },
  {
    title: '邮箱地址',
    isTrue: true
  },
  {
    title: '联系人',
    isTrue: true
  },
  {
    title: '联系人电话',
    isTrue: true
  },
  {
    title: '组织名称',
    isTrue: true
  },
  {
    title: '操作',
    isTrue: true
  },
  // {
  //   title: '客户标签',
  //   isTrue: true
  // }
];

